
/*
----------------------------------------------------------
COMPONENT: BUTTON
----------------------------------------------------------
*/

.btn {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  border: none;
  padding: 12px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
}

.btn:hover {
  background: #f1f1f1;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}
