/*
----------------------------------------------------------
PORTALS (re: modals)
----------------------------------------------------------
*/

#portals {
  display: none;
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  background: var(--primary-background)!important;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-device-width:1024px) and (orientation:landscape){
  #portals { display: none!important; }
}

#portals::-webkit-scrollbar {
  display: initial;
}

body.lock::-webkit-scrollbar,
#portals::-webkit-scrollbar {
  display: none;
}

#portals .inner {
  max-width: 500px;
  margin: 0 auto;
  padding: 24px 34px 44px 34px;
  background: var(--primary-background)!important;
  color: #000000;
}

#portals .portal__header a.close {
  display: block;
  text-align: right;
  margin-bottom: 20px;
}

#portals section p {
  margin-bottom: 20px;
}

#portals section a.close {
  display: block;
  padding: 15px 0 10px 0;
}

#portals a {
  text-decoration: underline;
}

#portals a:hover {
  text-decoration: none;
}

