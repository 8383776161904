
/*
==========================================================
COMPONENT: MEDIA ITEM
==========================================================
*/

.media__swipe__item img.voice-bubble-bottom {
  width: 30px;
  height: 18px;
  margin: 0;
  padding: 0;
}

/*
==========================================================
MOBILE-FIRST
==========================================================
*/

/* ----- CONTAINER ------------------------------------ */

.media__swipe__item__audio .group {
  position: relative;
  top: 0;
  left: 0;
}

/* ----- MIC ICON: FLAG COLORS ------------------------ */

.media__swipe__item__audio img.icon-mic-ua {
  width: 21px;
}

/* ----- CONTRIBUTOR DETAILS -------------------------- */

.media__swipe__item__audio .group .contributor {
  position: absolute;
  top: 23px;
  left: 69px;
}

.media__swipe__item__audio .group .contributor p.name {
  color: var(--media-item-color);
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 0;
}

.media__swipe__item__audio .group .contributor p.location {
  color: var(--media-item-color);
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  margin-right: 10px;
}

.media__swipe__item__audio .group .contributor img.icon-flag {
  width: 16px;
}

/* ----- CONTRIBUTOR PHOTO ---------------------------- */

.media__swipe__item__audio .group .contributor-photo {
  position: relative;
  top: 19px;
  left: 50%;
  width: 120px;
  height: 120px;
  transform: translateX(-50%);
}

.media__swipe__item__audio .group .contributor-photo img {
  position: absolute;
  top: 0;
  left: 0;
}

.media__swipe__item__audio .group .contributor-photo img.contributor {
  z-index: 1000;
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
}

.media__swipe__item__audio .group .contributor-photo img.icon-play {
  display: none;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 80px;
  height: 80px;
  opacity: .8;
}

.media__swipe__item__audio .group .contributor-photo img.icon-play:hover {
  opacity: 1;
}

.swiper-slide-deeplink .media__swipe__item__audio .group .contributor-photo img.icon-play {
  display: block;
}

/* ----- CLIP META ----------------------------------- */

.media__swipe__item__audio .group .duration {
  
  color: var(--media-item-color);
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
}

/* ----- WAVEFORM ------------------------------------- */

.media__swipe__item__audio .group .waveform {
  position: relative;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  margin: 0;
  width: 100%;
  max-width: 290px;
}

.media__swipe__item__audio .group .waveform > * {
  position: relative;
  top: 18px;
  height: 100px !important;
  overflow: hidden;
  margin: 0 auto;
  width: inherit;
  max-width: inherit;
}

.media__swipe__item__audio .group .waveform .waveform-noclick {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 290px;
  height: 70px!important;
}

/* ----- CAPTIONS ------------------------------------- */

.media__swipe__item__audio .group p.captions {
  color: var(--media-item-color);
  position: relative;
  top: 32px;
  font-size: 1.75vh;
  line-height: 2.25vh;
}

.media__swipe__item__audio p.captions span {
  display: inline-block;
  padding-right: .4em;
}

.media__swipe__item__audio p.captions span:last-child {
  padding-right: 0;
}

.media__swipe__item__audio .group textarea.captions {
  display: none;
}

/* ----- OVERRIDES: INTRO ANIMATION ------------------- */

/* devices 730px tall and smaller */
@media screen and (max-width:768px) and (height < 731px) {
  .media__swipe__item__intro img.animation { max-height: 66vh; }
}

/* not a real use case, but just to prevent overflow on the intro slide */
@media screen and (max-width:768px) and (height < 501px) {
  .media__swipe__item__intro img.animation { max-height: 60vh; }
}

/* ----- OVERRIDES: WAVEFORM -------------------------- */

/* devices 730px tall and smaller */
@media screen and (max-width:768px) and (height < 731px) {
  .media__swipe__item .waveform { height: 65px!important; }
}

/* ----- OVERRIDES: WAVEFORM OFFSETS ------------------ */

.offset_ml_minus_1 { margin-left: -1px!important; }
.offset_ml_minus_2 { margin-left: -2px!important; }
.offset_ml_minus_3 { margin-left: -3px!important; }
.offset_ml_minus_4 { margin-left: -4px!important; }
.offset_ml_minus_5 { margin-left: -5px!important; }
.offset_ml_minus_6 { margin-left: -6px!important; }
.offset_ml_minus_7 { margin-left: -7px!important; }
.offset_ml_minus_8 { margin-left: -8px!important; }
.offset_ml_minus_9 { margin-left: -9px!important; }
.offset_ml_minus_10 { margin-left: -10px!important; }


/*
==========================================================
RESPONSIVE: DESKTOP + TABLET (LANDSCAPE)

Note: captions are handled separately in next section
==========================================================
*/

@media screen and (width >= 600px) {

  /* ----- MIC ICON: FLAG COLORS ------------------------ */

  .media__swipe__item__audio img.icon-mic-ua {
    position: absolute;
    top: 36px;
    left: 139px;
    width: 33px
  }

  /* ----- CONTRIBUTOR PHOTO -------------------------- */

  .media__swipe__item__audio .group .contributor-photo {
    position: absolute;
    top: 28px;
    left: 40px;
    transform: none;
    width: 80px;
    height: 80px;
  }

  .media__swipe__item__audio .group .contributor-photo img.contributor {
    width: 80px;
    height: 80px;
  }

  .media__swipe__item__audio .group .contributor-photo img.icon-play {
    
  }

  /* ----- CONTRIBUTOR DETAILS ------------------------ */

  .media__swipe__item__audio .group .contributor {
    position: absolute;
    top: 30px;
    left: 150px;
  }

  .media__swipe__item__audio .group .contributor p.name {
    padding-left: 38px;
    padding-right: 20px;
    display: inline-block;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
  }

  .media__swipe__item__audio .group .contributor p.location {
    font-weight: 300;
    margin-right: 0;
  }

  .media__swipe__item__audio .group .contributor img.icon-flag {
    position: absolute;
    top: 2px;
    left: 0;
    width: 24px;
  }

  /* ----- MIC ICON ----------------------------------- */

  /* red mic */
  .media__swipe__item__audio img.icon-mic {
    position: absolute;
    top: 68px;
    left: 150px;
  }

  /* ----- CLIP META ---------------------------------- */

  .media__swipe__item__audio .group .duration {
    position: absolute;
    top: 74px;
    left: 189px;
    transform: none;
    font-size: 22px;
    font-weight: 300;
  }

  /* ----- WAVEFORM ----------------------------------- */

  .media__swipe__item__audio .group .waveform {
    position: absolute;
    top: 47px;
    left: 264px;
    transform: none;
  }

  .media__swipe__item__audio .group .waveform > * {
    top: 0;
    height: 75px!important;
  }

  /* ----- OVERRIDES: WAVEFORM OFFSETS ------------------ */

  .offset_ml_minus_1 { margin-left: -2px!important; }
  .offset_ml_minus_2 { margin-left: -4px!important; }
  .offset_ml_minus_3 { margin-left: -6px!important; }
  .offset_ml_minus_4 { margin-left: -8px!important; }
  .offset_ml_minus_5 { margin-left: -10px!important; }
  .offset_ml_minus_6 { margin-left: -12px!important; }
  .offset_ml_minus_7 { margin-left: -14px!important; }
  .offset_ml_minus_8 { margin-left: -16px!important; }
  .offset_ml_minus_9 { margin-left: -18px!important; }
  .offset_ml_minus_10 { margin-left: -20px!important; }

}

@media screen and (width >= 600px) and (width < 640px){
  .media__swipe__item__audio .group .waveform,
  .media__swipe__item__audio .group .waveform wave,
  .media__swipe__item__audio .group .waveform canvas {
    max-width: 220px;
  }
}

@media screen and (width >= 640px) and (width < 680px){
  .media__swipe__item__audio .group .waveform,
  .media__swipe__item__audio .group .waveform wave,
  .media__swipe__item__audio .group .waveform canvas {
    max-width: 260px;
  }
}

@media screen and (width >= 600px) and (width < 768px){
  .media__swipe__item__audio .group .contributor p.location {
    display: none;
  }
}

@media screen and (width >= 1024px) {

  /* ----- CONTRIBUTOR PHOTO -------------------------- */

  .media__swipe__item__audio .group .contributor-photo {
    left: 50px;
  }

  /* ----- MIC ICON: FLAG COLORS ------------------------ */

  .media__swipe__item__audio img.icon-mic-ua {
    position: absolute;
    top: 36px;
    left: 149px;
    width: 33px
  }

  /* ----- CONTRIBUTOR ---------------------------------- */

  .media__swipe__item__audio .group .contributor {
    left: 160px;
  }

  /* ----- DURATION ------------------------------------- */

  .media__swipe__item__audio .group .duration {
    left: 198px;
  }

  /* ----- WAVEFORM ------------------------------------- */

  .media__swipe__item__audio .group .waveform {
    left: 274px;
  }

}


/*
==========================================================
RESPONSIVE CAPTIONS: SMART PHONES
==========================================================
*/

@media screen and (width >= 360px) {
  .media__swipe__item__audio .group p.captions {
    font-size: 2.25vh;
    line-height: 2.75vh;
  }
}

@media screen and (width >= 360px) and (height <= 640px) {
  .media__swipe__item__audio .group p.captions {
    font-size: 2.1vh;
    line-height: 2.6vh;
  }
}

/*
==========================================================
RESPONSIVE CAPTIONS: TABLET
==========================================================
*/

@media screen and (width >= 600px) {
  .media__swipe__item__audio .group p.captions {
    position: relative;
    top: 17vh;
  }
}

@media screen and (width >= 600px) and (height >= 880px) {
  .media__swipe__item__audio .group p.captions {
    position: relative;
    top: 15vh;
  }
}

@media screen and (width >= 768px) {
  .media__swipe__item__audio .group p.captions {
    font-size: 3.5vh;
    line-height: 4.5vh;
  }
}

@media screen and (width >= 768px) and (height >= 880px) {
  .media__swipe__item__audio .group p.captions {
    font-size: 3.5vh;
    line-height: 4.5vh;
  }
}

@media screen and (width >= 768px) and (height >= 1000px) {
  .media__swipe__item__audio .group p.captions {
    font-size: 3vh;
    line-height: 4vh;
  }
}


/*
==========================================================
RESPONSIVE CAPTIONS: DESKTOP
==========================================================
*/

/* desktop */
@media screen and (width >= 960px) and (height > 1000px) {
  .media__swipe__item__audio .group p.captions {
    top: 13.5vh;
    font-size: 3.5vh;
    line-height: 4.5vh;
  }
}


/*
==========================================================
MUTE TOGGLE
==========================================================
*/

.media__swipe__item__audio button.toggle-audio {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  background: transparent;
  border: none;
  margin: 0;
}

.media__swipe__item__audio button.toggle-audio:hover img {
  border: 2px solid #c1c1c1;
}

.media__swipe__item__audio button.toggle-audio img {
  width: 40px;
  border: 2px solid #777;
  border-radius: 50%;
}

.media__swipe__item__audio button.toggle-audio img.disabled {
  display: none!important;
}

/* tablet */
@media screen and (width >= 600px) {
  .media__swipe__item__audio button.toggle-audio {
    padding: 24px 34px;
  }
  .media__swipe__item__audio button.toggle-audio img {
    width: 40px;
  }
}

/* desktop */
@media screen and (width >= 960px) and (height > 1000px) {
  .media__swipe__item__audio button.toggle-audio {
    padding: 24px 50px;
  }
  .media__swipe__item__audio button.toggle-audio img {
    width: 40px;
  }
}
