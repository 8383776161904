
/*
----------------------------------------------------------
COMPONENT: MEDIA
----------------------------------------------------------
*/

.media__swipe {
  padding: 10vh 0 0 0;
  overflow: hidden;
}

.media__swipe__vertical .swiper {
  padding: 0 14px;
}

/* devices 870px tall and smaller */
@media screen and (orientation:portrait) and (height <= 1000px) {
  .media__swipe { padding-top: 10vh; }
}

/* desktop */
@media screen and (width >= 600px) and (height <= 1000px) {
  .media__swipe { padding-top: 12vh; }
}

/* desktop */
@media screen and (width >= 600px) and (height > 1000px) {
  .media__swipe { padding-top: 18vh; }
}

/* desktop */
@media screen and (width > 1024px) and (height > 880px) {
  .media__swipe { padding-top: 18vh; }
}


/*
----------------------------------------------------------
JS LIBRARY CUSTOMIZATION: SWIPER NAVIGATION
----------------------------------------------------------
*/

@media screen and (orientation: portrait) and (width < 1024px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none!important;
  }

}

/*
@media screen and (width >= 1024px) {

  .swiper-button-prev,
  .swiper-button-next {
    z-index: 20000;
    top: initial;
    bottom: 0px;
    padding: 70px 20px;
    color: var(--media-item-color);
    opacity: 1!important;
  }

  .swiper-button-prev {
    left: 3%;
  }

  .swiper-button-next {
    right: 3%;
  }

}
*/
