
.landscape {
  width: 100%;
  background: #000000;
  display: none;
}

.landscape .inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landscape p {
  max-width: 80%;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

.landscape p.height-pass { display: initial; }
.landscape p.height-fail { display: none; }

/* disable: landscape view, height < 580px  */
@media only screen and (height < 580px) and (width < 768px) and (orientation: landscape) {
  .wrapper.block-landscape .portrait-only { display: none; }
  .wrapper.block-landscape .landscape { display: block!important; }
  .wrapper.allow-rotate { transform: rotate(90deg); }
}

/* disable: portrait, height < 580px */
@media only screen and (height < 580px) and (orientation: portrait) {
  .wrapper.block-landscape .portrait-only { display: none; }
  .wrapper.block-landscape .landscape { display: block!important; }
  .wrapper.allow-rotate { transform: rotate(90deg); }
}

/* unsupported device */
@media only screen and (height < 580px) and (width < 600px) and (orientation: landscape) {
  .landscape p.height-pass { display: none; }
  .landscape p.height-fail { display: initial; }
}

/* unsupported device */
@media only screen and (height < 580px) and (orientation: portrait) {
  .landscape p.height-pass { display: none; }
  .landscape p.height-fail { display: initial; }
}
