
/*
----------------------------------------------------------
COMPONENT: FOOTER
----------------------------------------------------------
*/

.footer {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-background);
  color: var(--primary-color);
  width: 100%;
}

.footer .inner {
  max-width: 500px;
  margin: 0 auto;
  padding: 25px 20px;
}

.footer .inner p {
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 25px;
}

.footer .inner .actions {
  margin: 0 auto;
}

.footer .actions .btn {
  display: block;
  width: 100%;
  max-width: 340px;
  border-radius: 35px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  letter-spacing: .3em;
  background: var(--btn-background);
  color: var(--btn-color);
}

/*
----------------------------------------------------------
RESPONSIVE
----------------------------------------------------------
*/

/* devices 870px tall and smaller */
@media screen and (max-width:768px) and (max-height:870px) {
  .footer .inner { padding-top: 15px; padding-bottom: 15px; }
  .footer .inner p { margin-bottom: 15px; }
  .footer .inner .btn { padding: 14px 0; }
}

/* devices 730px tall and smaller */
@media screen and (max-width:768px) and (max-height:730px) {
  .footer .inner { padding-top: 10px; padding-bottom: 10px; }
  .footer .inner p { margin-bottom: 10px; }
  .footer .inner .btn { padding: 12px 0; }
}

/* desktop */
@media screen and (width >= 600px) and (height > 1000px) {
  .footer .inner { padding-bottom: 14vh; }
}

/* desktop */
@media screen and (width > 1024px) and (height > 880px) {
  .footer .inner { padding-bottom: 14vh; }
}
