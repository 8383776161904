
#maintenance {
  padding: 40px 25px;
}

#maintenance h1 {
  margin-bottom: 32px;
}

#maintenance p {
  margin-bottom: 20px;
}

#maintenance a,
#maintenance a:visited {
  color: var(--primary-color-highlight);
  text-decoration: underline;
}

#maintenance a:hover {
  text-decoration: none;
}

#maintenance .block {
  display: block; 
}

#maintenance a.block {
  margin-bottom: 0;
}
