
/*
----------------------------------------------------------
COMPONENT: HEADER
----------------------------------------------------------
*/

.header {
  width: 100%;
  max-width: 1024px;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-background);
  color: var(--primary-color);
}

.header .inner {
  width: 100%;
  max-width: 1024px;
  position: relative;
  top: 0;
  left: 0;
}

.header .logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header .logo img {
  margin: 0 auto;
  width: 116px;
}

.header .legal {
  position: absolute;
  right: 14px;
  color: #5a5a5a;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  text-align: right;
}

.header .legal .btnLink {
  font-size: 12px;
  background: transparent;
  text-decoration: none;
}

.header .legal .btnLink:hover {
  text-decoration: underline;
}

/*
----------------------------------------------------------
RESPONSIVE
----------------------------------------------------------
*/

/* portrait: default */
.header, .header .inner { height: 12vh; }
.header .inner .legal { bottom: calc(6vh - 18px); }
.header .inner .logo { bottom: calc(6vh - 16px); }

/* portrait group 1: devices 730px tall and smaller */
@media screen and (width < 600px) and (height < 730px) {
  .header, .header .inner { height: 8vh; }
  .header .inner .legal { bottom: calc(3vh - 18px); }
  .header .inner .logo { bottom: calc(3vh - 16px); }
}

/* portrait group 2: devices 870px tall and smaller */
@media screen and (width < 600px) and (height >= 730px) {
  .header, .header .inner { height: 10vh; }
  .header .inner .legal { bottom: calc(5vh - 18px); }
  .header .inner .logo { bottom: calc(5vh - 16px); }
}

/* desktop */
@media screen and (width >= 600px) and (height > 1000px) {
  .header, .header .inner { height: 18vh; }
}

/* desktop */
@media screen and (width > 1024px) and (height > 880px) {
  .header, .header .inner { height: 18vh; }
}
