
/*
----------------------------------------------------------
PORTAL: LEGAL
----------------------------------------------------------
*/

h1#legal_dialog_label:focus { color:red!important; }

.portal__legal .btnLink {
  font-size: 16px;
  text-decoration: underline;
}

.portal__legal .btnLink:hover {
  text-decoration: none;
}
