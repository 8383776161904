/*
----------------------------------------------------------
COMPONENT: MEDIA ITEM - LAYOUT
----------------------------------------------------------
*/

.media__swipe__item {
  margin: 0 0 0 0;
  padding: 0;
  background: var(--primary-background)!important;
  overflow: hidden;
}

.media__swipe__item .inner {
  border-radius: 20px 20px 20px 0;
  background: var(--media-item-background)!important;
}

.media__swipe__item .inner .group {
  padding: 25px 25px;
}


/*
==========================================================
RESPONSIVE: DESKTOP
==========================================================
*/

@media screen and (width >= 800px) {

  .media__swipe__item .inner .group {
    padding: 25px 40px;
  }

}

@media screen and (width >= 1024px) {

  .media__swipe__item .inner .group {
    padding: 25px 50px;
  }

}


/*
----------------------------------------------------------
COMPONENT: MEDIA ITEM - COMMON STYLES
----------------------------------------------------------
*/

.swiper {
  height: inherit;
}

/* default (incl. desktop) */
.swiper-slide {
  background: var(--primary-background)!important;
}

.swiper-backface-hidden .swiper-slide {
  backface-visibility: initial!important;
}
