
/*
----------------------------------------------------------
RESETS
----------------------------------------------------------
*/

* {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
----------------------------------------------------------
CSS VARS
----------------------------------------------------------
*/

:root {

  --primary-background: #ffffff;
  --primary-color: #000000;
  --primary-color-highlight: #0a0a0a;
  --media-item-background: #000000;
  --media-item-color: #ffffff;
  --btn-background: #000000;
  --btn-color: #ffffff;

}

.dark-mode-display-block { display: none; } /* only show in dark mode */
.dark-mode-display-none { display: block; } /* default */

@media screen and (prefers-color-scheme: dark) {

  :root {
    --primary-background: #000000;
    --primary-color: #ffffff;
    --primary-color-highlight: #f0f0f0;
    --media-item-background: #ffffff;
    --media-item-color: #000000;
    --btn-background: #ffffff;
    --btn-color: #000000;
  }

  .dark-mode-display-block { display: block; }  /* default, in dark mode */
  .dark-mode-display-none { display: none; }    /* hide, in dark mode */

}

/*
----------------------------------------------------------
TYPE: FONT FACES
----------------------------------------------------------
*/

@font-face { font-family: 'IPM-Bold'; src: url('./fonts/IBMPlexMono-Bold.woff2') format('woff2'); }
@font-face { font-family: 'IPM-Light'; src: url('./fonts/IBMPlexMono-Light.woff2') format('woff2'); }
@font-face { font-family: 'IPM-Medium'; src: url('./fonts/IBMPlexMono-Medium.woff2') format('woff2'); }
@font-face { font-family: 'IPM-Regular'; src: url('./fonts/IBMPlexMono-Regular.woff2') format('woff2'); }
@font-face { font-family: 'IPM-Thin'; src: url('./fonts/IBMPlexMono-Thin.woff2') format('woff2'); }

.ipm-bold { font-family: 'IPM-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif; }
.ipm-light { font-family: 'IPM-Light', "Helvetica Neue", Helvetica, Arial, sans-serif; color: #5a5a5a; }
.ipm-medium { font-family: 'IPM-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif; }
.ipm-regular { font-family: 'IPM-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif; }
.ipm-thin { font-family: 'IPM-Thin', "Helvetica Neue", Helvetica, Arial, sans-serif; }

body, div,
a, blockquote, code, p,
button, input, select, option, textarea {
  font-family: 'IPM-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
----------------------------------------------------------
TYPE: STYLES
----------------------------------------------------------
*/

body, button, p, a, a:visited, h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
}

h1 { text-align: center; font-size: 26px; line-height: 31px; margin-bottom: 22px; }
h2 { text-align: center; font-size: 22px; line-height: 27px; margin-bottom: 20px; }
h3 { text-align: center; font-size: 20px; line-height: 25px; margin-bottom: 20px; }
h4 { text-align: center; font-size: 18px; line-height: 23px; margin-bottom: 20px; }
h5 { text-align: center; font-size: 16px; line-height: 21px; margin-bottom: 20px; }
h6 { text-align: center; font-size: 14px; line-height: 19px; margin-bottom: 20px; }

p { font-size: 16px; }

.bold, .strong { font-weight: bold; }
.em, .italic, .italics { font-style: italic; }
.text-small { font-size: 14px; }
.nowrap { white-space: nowrap; }

/*
----------------------------------------------------------
ACCESSIBILITY
----------------------------------------------------------
*/

.sr-only {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#ally-nav a {
  display: inline;
  margin-right: 10px;
}

a:focus,
button:focus {
  text-decoration: underline!important;
}

/*
----------------------------------------------------------
CONTAINERS
----------------------------------------------------------
*/

body {
  overflow: hidden;
  background: var(--primary-background);
}

div {
  user-select: none;
}

.wrapper {
  width: 100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 500px;
  min-width: 320px;
  margin: 0 auto 40px auto; 
  overflow: auto;
  min-height: 300px;
  border-radius: 5px;
}

@media screen and (width > 500px) {
  .container {
    max-width: 1024px;
  }
}

/*
----------------------------------------------------------
HELPERS: RESPONSIVE
----------------------------------------------------------
*/

.mobile-only { display: initial!important; }
.desktop-only { display: none!important; }

@media all and (width >= 768px) {
  .mobile-only { display: none!important; }
  .desktop-only { display: initial!important; }
}

.show-tablet { display: none!important; }
.show-desktop { display: none!important; }

@media all and (width >= 600px) {
  .show-tablet { display: initial!important; }
}

@media all and (width >= 768px) {
  .show-desktop { display: initial!important; }
}

/*
----------------------------------------------------------
HELPERS: CSS ANIMATIONS
----------------------------------------------------------
*/

.fade-in {
  animation: fade-in 1s;
}

@keyframes fade-in {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}

.fade-out {
  animation: fade-out 1s;
}

@keyframes fade-out {
	from {
		opacity:1;
	}
	to {
		opacity:0;
	}
}

/*
----------------------------------------------------------
HELPERS: PADDING
----------------------------------------------------------
*/

.pt0 { padding-top: 0!important; }
.pt5 { padding-top: 5px!important; }
.pt10 { padding-top: 10px!important; }
.pt15 { padding-top: 15px!important; }
.pt20 { padding-top: 20px!important; }
.pt25 { padding-top: 25px!important; }
.pt30 { padding-top: 30px!important; }
.pt35 { padding-top: 35px!important; }
.pt40 { padding-top: 40px!important; }
.pt45 { padding-top: 45px!important; }
.pt50 { padding-top: 50px!important; }
.pt55 { padding-top: 55px!important; }
.pt60 { padding-top: 60px!important; }
.pt65 { padding-top: 65px!important; }
.pt70 { padding-top: 70px!important; }
.pt75 { padding-top: 75px!important; }

.pr0 { padding-right: 0!important; }
.pr5 { padding-right: 5px!important; }
.pr10 { padding-right: 10px!important; }
.pr15 { padding-right: 15px!important; }
.pr20 { padding-right: 20px!important; }
.pr25 { padding-right: 25px!important; }
.pr30 { padding-right: 30px!important; }
.pr35 { padding-right: 35px!important; }
.pr40 { padding-right: 40px!important; }
.pr45 { padding-right: 45px!important; }
.pr50 { padding-right: 50px!important; }
.pr55 { padding-right: 55px!important; }
.pr60 { padding-right: 60px!important; }
.pr65 { padding-right: 65px!important; }
.pr70 { padding-right: 70px!important; }
.pr75 { padding-right: 75px!important; }

.pb0 { padding-bottom: 0!important; }
.pb5 { padding-bottom: 5px!important; }
.pb10 { padding-bottom: 10px!important; }
.pb15 { padding-bottom: 15px!important; }
.pb20 { padding-bottom: 20px!important; }
.pb25 { padding-bottom: 25px!important; }
.pb30 { padding-bottom: 30px!important; }
.pb35 { padding-bottom: 35px!important; }
.pb40 { padding-bottom: 40px!important; }
.pb45 { padding-bottom: 45px!important; }
.pb50 { padding-bottom: 50px!important; }
.pb55 { padding-bottom: 55px!important; }
.pb60 { padding-bottom: 60px!important; }
.pb65 { padding-bottom: 65px!important; }
.pb70 { padding-bottom: 70px!important; }
.pb75 { padding-bottom: 75px!important; }

.pl0 { padding-left: 0!important; }
.pl5 { padding-left: 5px!important; }
.pl10 { padding-left: 10px!important; }
.pl15 { padding-left: 15px!important; }
.pl20 { padding-left: 20px!important; }
.pl25 { padding-left: 25px!important; }
.pl30 { padding-left: 30px!important; }
.pl35 { padding-left: 35px!important; }
.pl40 { padding-left: 40px!important; }
.pl45 { padding-left: 45px!important; }
.pl50 { padding-left: 50px!important; }
.pl55 { padding-left: 55px!important; }
.pl60 { padding-left: 60px!important; }
.pl65 { padding-left: 65px!important; }
.pl70 { padding-left: 70px!important; }
.pl75 { padding-left: 75px!important; }
