/*
----------------------------------------------------------
COMPONENT: MEDIA ITEM - INTRO SLIDE
----------------------------------------------------------
*/

.media__swipe__item__intro .inner {
  position: relative;
}

.media__swipe__item__intro h1 {
  position: absolute;
  z-index: 3000;
  top: 24px;
  left: 24px;
  color: var(--media-item-color);
  text-align: left;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
}

.media__swipe__item__intro img.animation {
  position: absolute;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* max-width: 260px; */
  max-height: 95%;
}

.media__swipe__item__intro p {
  position: absolute;
  z-index: 3000;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--media-item-color);
  text-align: center;
  width: 100%;
}


/*
----------------------------------------------------------
RESPONSIVE
----------------------------------------------------------
*/

@media screen and (max-width:440px) {
  .media__swipe__item__intro h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .media__swipe__item__intro img.animation {
    max-height: 90%;
  }
}

@media screen and (max-width:420px) {
  .media__swipe__item__intro img.animation {
    max-height: 80%;
  }
}

@media screen and (height < 800px) {
  .media__swipe__item__intro p {
    bottom: 8%;
  }
}
